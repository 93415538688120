<template>
	<div class="w-100">
		<!-- Contraception Options -->
		<Checkboxes
			required
			:values="contraceptions"
			name="contraceptiveMethods"
			getter="getContraceptiveMethods"
			updateMethod="updateContraceptiveMethods"
			title="Are you currently using any of these contraceptive methods?"
			subtitle="This might be to protect against pregnancy or STIs. Please select all that apply."
		/>

		<!-- Contraception Failure Options -->
		<Radios
			v-if="usedContraception"
			required
			:values="failedContraceptions"
			name="contraceptionFailure"
			getter="getSuspectedContractionFailure"
			updateMethod="updateSuspectedContractionFailure"
			title="Do you think your contraceptive method might have failed?"
			subtitle="This could be due to missing a pill, a condom breaking or your method being out of date."
		/>
	</div>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Form Components
import Checkboxes from '@/components/form/checkbox/group'
import Radios from '@/components/form/radio/group'

export default {
	components: {
		Radios,
		Checkboxes
	},
	data() {
		return {
			usedContraception: null,
			contraceptions: [
				{
					name: "Condoms",
					id: "condoms",
					image: "condoms"
				}, {
					name: "The pill",
					id: "the-pill",
					image: "pill"
				}, {
					name: "Implant",
					id: "implant",
					image: "implant"
				}, {
					name: "Injection",
					id: "injection",
					image: "injection"
				}, {
					name: "A Coil",
					id: "coil",
					image: "coil"
				}, {
					name: "Patch or ring",
					id: "patch-or-ring",
					image: "patch"
				}, {
					name: "No method being used",
					id: "no-method"
				}
			],
			failedContraceptions: [
				{
					name: "Yes",
					id: "yes-failed"
				}, {
					name: "No",
					id: "no-failed"
				}, {
					name: "I'm not sure",
					id: "im-not-sure-failed"
				},
			]
		}
	},
	computed: {
		...mapGetters([
			'getContraceptiveMethods'
		])
	},
	watch: {
		getContraceptiveMethods(value) {
			if ( value.includes('No method being used') || !value.length ) {
				this.usedContraception = false
				this.$announcer.polite('The form has been updated.')
				this.$store.dispatch('clearContraceptiveFailure', '')

			} else {
				this.usedContraception = true
				this.$announcer.polite('The form has been updated.')

				// Remove checked state from local store
				const id = document.getElementById('no-method')
				id.checked = false
			}

			// Clear checkboxes based on options
			if ( value.length > 1 && value.includes('No method being used') ) {
				if ( value.indexOf("No method being used") === 0 ) {
					// If other is selected and values after are valid
					this.$store.dispatch('clearContraceptiveMethodsOptions', "No method being used")
				} else {
					// If values are selected and other is selected
					this.$store.dispatch('clearContraceptiveMethods', ["No method being used"])
				}
			}
		}
	},
	methods: {
		checkState() {
			// Check stored state
			if ( this.getContraceptiveMethods.length && !this.getContraceptiveMethods.includes('No method being used')  ) {
				this.usedContraception = true
			}
		},
	},
	mounted() {
		this.checkState()
	},
}
</script>