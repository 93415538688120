<template>
	<div class="w-100">
		<!-- Partners Options -->
		<Checkboxes
			required
			title="Do you have a partner(s)?"
			subtitle="Please select all that apply"
			:values="partners"
			name="partners"
			getter="getPartners"
			updateMethod="updatePartners"
		/>

		<!-- Partners Other Option -->
		<form-row v-if="hasPartner">
			<form-input
				required
				type="number"
				name="partnersAge"
				dataStore="form"
				updateMethod="updatePartnersAge"
				label="How old is your partner?"
			/>
		</form-row>
	</div>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import formRow from '@/components/form/form-row'
import formInput from '@/components/form/form-input'
import Checkboxes from '@/components/form/checkbox/group'

export default {
	components: {
		formRow,
		Checkboxes,
		formInput
	},
	data() {
		return {
			hasPartner: null,
			partners: [
				{
					name: "I have a boyfriend",
					id: "boyfriend"
				}, {
					name: "I have a girlfriend",
					id: "girlfriend"
				}, {
					name: "I have a partner",
					id: "partner"
				}, {
					name: "I have sex with someone but we are not exclusive",
					id: "non-exclusive"
				}, {
					name: "I have multiple partners",
					id: "multiple-partners"
				}, {
					name: "No",
					id: "no-partners"
				}
			]
		}
	},
	computed: {
		...mapGetters([
			'getPartners'
		])
	},
	watch: {
		getPartners(value) {
			if (value.includes('No') || !value.length) {
				this.hasPartner = false
				this.$announcer.polite('The form has been updated.')
				
			} else {
				this.hasPartner = true
				this.$announcer.polite('The form has been updated.')

				// Remove checked state from local store
				const id = document.getElementById('no-partners')
				id.checked = false
			}

			// Clear checkboxes based on options
			if ( value.length > 1 && value.includes('No') ) {
				if ( value.indexOf("No") === 0 ) {
					// If other is selected and values after are valid
					this.$store.dispatch('clearPartnersOptions', "No")
				} else {
					// If values are selected and other is selected
					this.$store.dispatch('clearPartners', ["No"])
				}
			}
		}
	},
	methods: {
		checkState() {
			// Check stored state
			if ( this.getPartners.length && !this.getPartners.includes('No')  ) {
				this.hasPartner = true
			}
		},
	},
	mounted() {
		this.checkState()
	},
}
</script>