<template>
  <div>
		<page-header
			title="More about you - Relationships and Sex"
		>
			<template v-slot:content>
				Thanks for that! Now we are going to ask some questions about your life and experiences. You might wonder why we are asking you these, but they help us to make sure you are well supported today. Remember, we will not tell anyone what you tell us, unless we are worried about you or someone else.
			</template>
		</page-header>
		<page-container>
			<form
				autocomplete="off"
				@submit.prevent="validateForm"
			>
				<!-- Partners Options -->
				<partners />
				<!-- Sex Options -->
				<sex />
				<!-- Contraception Options -->
				<contraception />

				<form-submit title="Continue to your life" />
			</form>
		</page-container>
  </div>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Mixins
import scrollToError from '@/mixins/scroll-to-error'
import mixpanelTracking from '@/mixins/mixpanel'

// Form Components
import pageHeader from '@/components/general/page-header'
import pageContainer from '@/components/general/page-container'
import formSubmit from '@/components/form/form-submit'

// Steps Components
import partners from '@/components/steps/more-about-you/partners'
import sex from '@/components/steps/more-about-you/sex'
import contraception from '@/components/steps/more-about-you/contraception'

export default {
	mixins: [scrollToError, mixpanelTracking],
	components: {
		pageHeader,
		pageContainer,
		formSubmit,
		partners,
		sex,
		contraception,
	},
	computed: {
		...mapGetters([
			'getPartners',
			'getPartnersAge',
			'getGenitals',
			'getHasHadSex',
			'getHasHadUpsi',
			'getContraceptiveMethods',
			'getSuspectedContractionFailure'
		])
	},
	methods: {
		async validateForm() {
			// Clear loading state
			this.$store.commit('loading', false)

			// Check form validations
			if (
				!!this.getPartners.length &&
				!!this.getGenitals &&
				!!this.getHasHadSex &&
				!!this.getContraceptiveMethods.length
			) {
				// Check other conditions
				if (
					!this.getPartners.includes('No') && this.getPartners.length && !this.getPartnersAge.length ||
					this.getHasHadSex.length && this.getHasHadSex != 'No' && !this.getHasHadUpsi.length ||
					!this.getContraceptiveMethods.includes('No method being used') && this.getContraceptiveMethods.length && !this.getSuspectedContractionFailure.length
				) {
					// Set errrors
					await this.$store.commit('updateErrors', {
						...this.getErrors,
						partnersAge: !this.getPartners.includes('No') && this.getPartners.length && !this.getPartnersAge.length ? 'Please enter how old they are' : '',
						hasHadUpsi: this.getHasHadSex.length && !this.getHasHadSex != 'No' && !this.getHasHadUpsi.length  ? 'Please select if you have had sex without a condom' : '',
						contraceptionFailure: !this.getContraceptiveMethods.includes('No method being used') && this.getContraceptiveMethods.length && !this.getSuspectedContractionFailure.length ? 'Please select an option' : ''
					})

					// Scroll to error
					await this.scrollToError()
					// Cancel submission
					return
				}

				// Set stepC to complete
				await this.$store.commit('completeStepC', true)
				await this.$store.dispatch('clearErrors')

				// Proceed to next step
				await this.$router.push(
					this.$GLOBALS.PATH_YOUR_LIFE
				)
			} else {
				// Set errrors
				await this.$store.commit('updateErrors', {
					...this.getErrors,
					partners: !this.getPartners.length ? 'Please select if you do or do not have a partner' : '',
					partnersAge: !this.getPartners.includes('No') && this.getPartners.length && !this.getPartnersAge.length ? 'Please enter how old they are' : '',
					genitals: !this.getGenitals.length  ? 'Please select the genitals that you have' : '',
					hasHadSex: !this.getHasHadSex.length  ? 'Please select whether you have had sex' : '',
					hasHadUpsi: this.getHasHadSex.length && !this.getHasHadSex != 'No' && !this.getHasHadUpsi.length  ? 'Please select if you have had sex without a condom' : '',
					contraceptiveMethods: !this.getContraceptiveMethods.length ? 'Please choose your methods of contraception if you have any' : '',
					contraceptionFailure: !this.getContraceptiveMethods.includes('No method being used') && this.getContraceptiveMethods.length && !this.getSuspectedContractionFailure.length ? 'Please select an option' : ''
				})

				// Scroll to error
				await this.scrollToError()
			}
		}
	}
}
</script>
