<template>
	<div class="w-100">
		<!-- Genitals Options -->
		<Radios
			required
			:values="genitals"
			name="genitals"
			getter="getGenitals"
			updateMethod="updateGenitals"
			title="What genitals do you have?"
			subtitle="We're asking this so we can give you the best possible advice about the contraception that's right for you as well as about any risk of pregnancy"
		/>

		<!-- Sex Options -->
		<Radios
			required
			:values="hasHadSex"
			name="hasHadSex"
			getter="getHasHadSex"
			updateMethod="updateHasHadSex"
			title="Have you ever had sex?"
			subtitle="When we talk about 'sex', we mean oral sex, anal sex and vaginal sex."
		/>

		<!-- Contraception Confirmation Options -->
		<Radios
			v-if="hasNotHadSex"
			required
			:values="hasHadUpsi"
			name="hasHadUpsi"
			getter="getHasHadUpsi"
			updateMethod="updateHasHadUpsi"
			title="Have you had sex without a condom with someone new, since your last STI test?"
		/>
	</div>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import Radios from '@/components/form/radio/group'

export default {
	components: {
		Radios
	},
	data() {
		return {
			hasNotHadSex: false,
			genitals: [
				{
					name: "Penis",
					id: "penis"
				}, {
					name: "Vagina",
					id: "vagina"
				}, {
					name: "I'm not sure",
					id: "im-not-sure-genitals"
				}
			],
			hasHadSex: [
				{
					name: "Yes",
					id: "yes-sex"
				}, {
					name: "No",
					id: "no-sex"
				}, {
					name: "I'm not sure",
					id: "im-not-sure-sex"
				}
			],
			hasHadUpsi: [
				{
					name: "Yes",
					id: "yes-without-condom"
				}, {
					name: "No",
					id: "no-without-condom"
				}, {
					name: "I'm not sure",
					id: "im-not-sure-without-condom"
				}
			]
		}
	},
	computed: {
		...mapGetters([
			'getHasHadSex',
		])
	},
	watch: {
		getHasHadSex(value) {
			// Check if the user has had sex
			if ( value === 'No' || !value.length ) {
				this.hasNotHadSex = false
			} else {
				this.hasNotHadSex = true
				this.$announcer.polite('The form has been updated.')
			}
		}
	},
	methods: {
		checkState() {
			// Check stored state
			if ( this.getHasHadSex.length && this.getHasHadSex != 'No' ) {
				this.hasNotHadSex = true
			}
		},
	},
	mounted() {
		this.checkState()
	},
}
</script>